.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #1c1f2c;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Overwriting link colors for all the project */
a:link {
    color: #e03e48;
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: #e03e48;
    background-color: transparent;
    text-decoration: none;
}

a:hover {
    color: #e03e48;
    background-color: transparent;
    text-decoration: underline;
}

a:active {
    color: #e03e48;
    background-color: transparent;
    text-decoration: underline;
}
